// Swiper
@import "node_modules/swiper/src/swiper.scss";

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

// Photoswipe
@import "node_modules/photoswipe/src/css/default-skin/default-skin.scss";
@import "node_modules/photoswipe/src/css/main.scss";

// Tailwind and normalize
@import "vendors/normalize", "vendors/tailwind";
